<template>
    <template-card-base
        v-bind="$attrs"
        v-on="$listeners"
        :template="template"
        :waypoint-id="waypointId"
        :event-label="eventLabelById(template.EventTypeID)"
        :order-type-label="orderTypeLabelById(template.OrderTypeID)"
        :platform-label="platformLabelById(template.PlatformTypeID)"
        :waypoint-label="waypointLabelById(template.WaypointID)"
        :selected="selected"
        :showActions="showActions"
        :platform-types="platformTypes"
        :order-types="orderTypes"
    />
</template>

<script>
import {mapGetters} from "vuex";
import TemplateCardBase from "components/messaging/TemplateCardBase";
import metadata from "mixins/messagingMetadata";

export default {
    name: "TemplateCardWrapper",
    components: {TemplateCardBase},
    props: {
        template: {
            type: Object,
            required: true,
        },
        selected: {
            type: Boolean,
            required: true,
        },
        showActions: {
            type: Boolean,
            required: false,
            default: false,
        },

        // workaround until waypoint Id would be properly passed from the backend
        waypointId: {
            required: true,
        }
    },
    mixins: [metadata],
    computed: {
        ...mapGetters("AirportStore", ["locations"]),
    },
    methods: {
        waypointLabelById(id) {
            if (!id || id === 0) {
                return null;
            }

            const waypoint = this.locations.find(x => x.waypointID === id);
            return waypoint ? `${waypoint.name}${waypoint.terminal ? '-' : ''}${waypoint.terminal}, ${waypoint.airportIdent}` : `Unknown waypoint with id ${id}`;
        },
    },
}
</script>

<style scoped>

</style>
