var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "template-card-base",
    _vm._g(
      _vm._b(
        {
          attrs: {
            template: _vm.template,
            "waypoint-id": _vm.waypointId,
            "event-label": _vm.eventLabelById(_vm.template.EventTypeID),
            "order-type-label": _vm.orderTypeLabelById(
              _vm.template.OrderTypeID
            ),
            "platform-label": _vm.platformLabelById(
              _vm.template.PlatformTypeID
            ),
            "waypoint-label": _vm.waypointLabelById(_vm.template.WaypointID),
            selected: _vm.selected,
            showActions: _vm.showActions,
            "platform-types": _vm.platformTypes,
            "order-types": _vm.orderTypes,
          },
        },
        "template-card-base",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }